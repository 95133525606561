<!--用户批量充值-->
<template>
    <div class="view">
        <el-input
            v-model="userList"
            :rows="10"
            type="textarea"
            :placeholder="lang['html.input.placeholder']"
            v-loading="isLoading"
        />
        <div style="margin-top: 10px">
            <el-popconfirm :title="lang['html.popconfirm.title']" @confirm="submit">
                <template #reference>
                    <el-button type="primary" :disabled="isLoading">{{lang['html.button.submit']}}</el-button>
                </template>
            </el-popconfirm>
            <el-button type="warning" :disabled="isLoading" @click="userList = ''">{{lang['html.button.reset']}}</el-button>
            <el-tag type="info" style="margin-left: 10px">{{lang['html.tag.userNum']}}:{{getUserLength}}</el-tag>
        </div>

        <el-tag style="margin-top: 10px">{{lang['html.tag.money']}}:{{money}}</el-tag>
        <br>
        <el-tag style="margin-top: 10px" type="warning">{{lang['html.tag.tips']}}</el-tag>
    </div>
</template>

<script>
import {fromBase64} from "js-base64";

export default {
    name: "userAddMoneyBatch",
    data(){
        return {
            //代理商余额[充值]
            money:-1,
            userList:'',
            isLoading:true,
            lang:this.$lang.getLang('user','userAddMoneyBatch'),
        }
    },
    computed:{
        getUserLength(){
            return this.getUserArr().length;
        },
    },
    methods:{
        //取出待充值的用户数组
        getUserArr(){
            let arr = this.userList.split('\n');
            let res = [];
            for (let arrKey in arr) {
                let temp = arr[arrKey];
                if (temp.length > 5){
                    res.push(temp);
                }
            }
            return res;
        },
        //查询自身余额
        getSelfMoney(){
            this.$api.post('Agent.AgentData/getSelfMoney').then(res=>{
                let data = this.$helper.checkRes(res);
                if(!data){
                    this.$alert(this.lang['getSelfMoney.error']);
                    return;
                }
                this.money = data.money_rec ?? -1;
            }).catch(err=>{
                this.$helper.axiosCatch(err);
            }).finally(()=>this.isLoading = false);
        },
        submit(){
            let arr = this.getUserArr();
            if (arr.length <= 0){
                this.$alert(this.lang['submit.error.list']);
                return;
            }
            this.$notify({message:this.lang['submit.wait'],type:'info'});
            this.isLoading = true;
            this.$api.post('User.Money/batchUserMoney',{list:arr.toString()}).then(res=>{
                let data = this.$helper.checkRes(res);
                if (data){
                    this.$alert(this.lang['submit.code.100']);
                    this.getSelfMoney();
                    return;
                }
                let code = this.$helper.getResErrorCode(res);
                this.$helper.alertErrMsg(this.lang,'submit.code.',code);
            }).catch(err=>{
                this.$helper.axiosCatch(err);
            }).finally(()=>this.isLoading=false);
        },
    },
    mounted() {
        this.getSelfMoney();
        let query = this.$route.params.userList;
        if (query){
            this.$notify({
                message:'Importing...',
                type: 'info'
            });
            let str = fromBase64(query);
            this.userList = str.replace(/,/g,"----\n") + '----';
        }
    }
}
</script>

<style scoped>
.view{
    width: 450px;
    margin:0 auto;
    margin-top: 20px;
}
</style>